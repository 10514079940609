.loader-container {
  height: 100vh;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  background: black;
}
/* FIRST PAGE */
#single-nft-container {
  position: relative;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
}
#artwork-container {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

#artwork {
  // object-fit: cover;
  height: 100vh;
  // width: 100%;
}

.artwork-details {
  position: absolute;
  width: auto;
  height: auto;
  min-width: 300px;
  min-height: 80px;
  top: 10rem;
  left: 0;
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}
.name-container {
  display: flex;
  width: auto;

  p {
    margin-right: 8px;
  }
}

.author-container {
  display: flex;
  p {
    margin-right: 8px;
  }
}
.artwork-details-label {
  font-weight: bolder;
}

/* SECOND PAGE */

#wrapper {
  display: flex;
  height: 100vh;
}
.artwork-container-small {
  width: 60%;
  height: 100%;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

#artwork-small {
  object-fit: contain;
  width: 100%;
  height: 80%;
}

.data-container {
  display: flex;
  flex-direction: column;
  width: 40%;
  justify-content: center;
  height: 80%;
  align-self: center;
}

.nft-title-info {
  text-align: right;
  color: white;
  margin: 0 3rem 1rem 0;
  font-weight: 800;
  font-size: 24px;
}

.check-name-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.nft-info-container {
  display: flex;
  justify-content: space-between;
}

.author-name {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.links-with-check {
  width: 15%;
}
.nft-info {
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  background-color: black;
  border: 1px solid white;
  padding: 2rem;
  margin: 0 3rem 0 -10%;
  font-size: clamp(0.7rem, 1vw, 1.5rem);
}

.description-container {
  word-break: break-word;
}

.nft-description {
  padding: 1em 0 1em 0;
  font-weight: bolder;
}

.nft-description-text {
  line-height: 2em;
  font-size: 0.9em;
}

.first-row {
  z-index: 99;
}

.second-row {
  padding-top: 5%;
  height: 40%;
  display: flex;
  justify-content: center;
  margin: 0 3rem 0 0;
}

.third-row {
  position: absolute;
  bottom: 0;
  margin-left: 32px;
  height: 80px;
}

.navigation-arrows {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;

  .left-arrow-container {
    cursor: pointer;
    color: white;
  }

  .go-to-details {
    color: white;
    font-size: 16px;
    font-weight: bolder;
    cursor: pointer;
  }
}
.arrow-container {
  cursor: pointer;
  margin-right: -6rem;
}
.go-back-text {
  color: white;
  font-size: 16px;
  font-weight: bolder;
  text-align: right;
  // margin-right: -1rem;
}
.go-back {
  color: white;
  cursor: pointer;
}

.links-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: white;
  text-align: center;
  width: 100%;
  padding-left: 3rem;

  .links-row {
    width: 100%;
    margin: 0 auto;
  }

  .links-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    // justify-content: right;
  }

  .link-label {
    width: 100%;
  }

  .onchain-info-container {
    height: 30%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
}

.link-btn {
  padding: 8px 12px;
  color: white;
  background-color: black;
  font-weight: bolder;
  border-radius: 20px;
  border: 2px solid white;
  cursor: pointer;
  margin: 0.5rem;
  font-size: clamp(0.6rem, 1vw, 0.7rem);
  flex: 2;
  max-width: 350px;
}

/* MOBILE */

.mobile-container {
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile-artwork-details {
  width: 90%;
  padding: 0;
  height: 15%;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;
  margin-bottom: 1rem;

  @media only screen and (max-height: 630px) {
    display: none;
  }
}

.mobile-artwork-details-label {
  @extend .artwork-details-label;
}

.check {
  width: 15%;
  padding: 0 1rem 0 0;
}

.mobile-artwork-container {
  width: 90%;
  margin: 0 auto;
  position: relative;

  #mobile-artwork {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.back-arrow-container {
  margin-top: 1rem;
  cursor: pointer;
  position: relative;
  width: 30%;
  min-width: 130px;
  padding: 0.5rem;
  background: linear-gradient(
    50deg,
    rgba(1, 1, 1, 1) 0%,
    rgb(43, 43, 43) 50%,
    rgb(48, 48, 48) 100%
  );
}

.back-arrow-container-desktop {
  cursor: pointer;
  padding: 0.5rem;
  // padding: 2rem 1rem 0.5rem 1rem;
  position: relative;
  // min-width: 130px;
  width: 180px;
  box-sizing: border-box;
  height: 100%;

  &.right {
    background: linear-gradient(
      230deg,
      rgb(0, 0, 0) 0%,
      rgb(29, 29, 29) 50%,
      rgb(48, 48, 48) 100%
    );
  }
  &.left {
    background: linear-gradient(
      140deg,
      rgb(0, 0, 0) 0%,
      rgb(29, 29, 29) 50%,
      rgb(48, 48, 48) 100%
    );
  }
}
.back-arrow-container-desktop-prev {
  @extend .back-arrow-container-desktop;
}

.MuiDrawer-paperAnchorBottom {
  background-color: black !important;
  border-radius: 16px 16px 0 0;
}

.bottom-drawer {
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 90%;
  height: 10vh;
  left: 50%;
  transform: translate(-50%);
  margin: 0 auto;
  background-color: black;
  position: absolute;
  bottom: 0;
  border-radius: 16px 16px 0 0;

  .chevron-container {
    position: absolute;
    bottom: 70%;
  }
}

#drawer-container {
  background-color: black;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
  padding-bottom: 2rem;

  .close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .description-container {
    margin-top: 1rem;

    .mobile-nft-info {
      margin: 0 1rem;
    }

    .description {
      font-size: 1em;
      padding: 0 1em 1em 1em;
    }

    .drawer-headings {
      font-weight: bolder;
      font-size: 1.3em;

      p {
        padding-top: 8px;
      }
    }
  }

  .links-row-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 10px 0px 10px 0;
  }

  .links-row-2-inner {
    display: flex;
    flex-direction: column;

    margin: 10px 0px 10px 0;
  }
}

/* MEDIA QUERIES */

@media screen and (max-height: 580px) {
  .onchain-info-container {
    height: 40%;
  }
  .MuiDrawer-paperAnchorBottom {
    border-radius: 0;
  }

  #drawer-container {
    height: 100vh;

    .links-row {
      display: flex;
    }
    .description-container {
      .description {
        font-size: 13px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .links-container {
    display: flex;
    justify-content: start;
    height: 15rem;
  }
  .nft-description-text {
    line-height: normal;
  }
}

@media screen and (max-width: 1200px) {
  .links-with-check {
    width: 20%;
  }
}

@media screen and (min-width: 500px) {
  .mobile-artwork-container {
    .check {
      top: -3em;
    }
  }
}

@media screen and (min-width: 700px) {
  .mobile-artwork-container {
    .check {
      top: -4em;
    }
  }
}

@media screen and (min-width: 2250px) {
  .links-container {
    .links-btn-container {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .link-btn {
    min-width: 200px;
  }
}

@media screen and (max-width: 1000px) and (max-height: 600px) {
  .nft-info {
    padding: 1rem;
  }
  .link-label {
    display: none;
  }
  .links-container {
    height: unset;
    padding: 0;
    justify-content: space-evenly;
  }
  .nft-title-info {
    margin: 0 0 0.5rem 0;
    //display: none;
    font-size: 18px;
  }
  .second-row {
    padding-top: 2%;
    margin: 0;
  }
}

@media screen and (min-width: 769px) and (max-width: 1140px) {
  .link-btn {
    font-size: 8px;
  }
}

@media screen and (min-width: 769px) and (max-width: 959px) {
  .links-container {
    padding: 0;
  }
  .link-btn {
    margin: 0.3rem;
  }
}

// @media screen and (min-height: 820px) {
//   .third-row {
//     bottom: 8rem;
//   }
// }

// @media screen and (max-height: 550px) {
//   .third-row {
//     bottom: 4rem;
//   }
// }

// @media screen and (min-height: 1000px) {
//   .third-row {
//     bottom: 20%;
//   }
// }
