.side-menu-container {
    display: flex;
    position: absolute;
    left: 0;
    top: 50%;
    justify-content: space-evenly;
    transform: translate(0%, -50%);
    flex-direction: column;
    padding-left: 4%;
    height: 40vh;
    .social-icon {
        cursor: pointer;
    }
}

.mobile-side-menu-container {
    @extend .side-menu-container;
    flex-direction:row;
    top: 100%;
    height: 20vh;
    padding-left: 0;
    width:100%;
}