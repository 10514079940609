@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
* {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

a {
  text-decoration: none;
}

#root {background: black}
