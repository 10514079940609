.error-page-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .error-image {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 0;
    display: block;
    object-fit: cover;
  }
  .back-btn {
    /* position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translate(-50%); */
    padding: 12px 5rem;
    color: white;
    background-color: transparent;
    font-weight: bolder;
    border-radius: 20px;
    border: 2px solid white;
    cursor: pointer;
    width: fit-content;
    text-align: center;
    z-index: 1;
    margin-bottom: 10rem;
  }
}
