$backgroundImage: "../../images/background.jpg";
$backgroundImageLight: "../../images/background-light-2.jpg";
#forma-wrapper::before {
  background-color: rgb(0, 0, 0);
}

#form-wrapper {
  position: relative;
  height: 100vh;
  background-image: url(#{$backgroundImageLight});
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .form-container {
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    padding: 2rem 2rem;

    p {
      margin: 0.5em;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60%;
    .form-input {
      display: flex;
      flex-direction: column;
      height: 50%;
      justify-content: space-evenly;
    }

    .input {
      border-radius: 12px;
      padding: 8px;

      &.error {
        border: 1px solid red;
        color: red;

        ::placeholder {
          color: red;
        }
      }
    }
  }
  .radio-btn-container {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    align-self: center;
    align-items: center;

    .radio-container {
      display: flex;
      align-items: baseline;
    }
  }

  #action-btn {
    border-radius: 20px;
    border: 4px solid white;
    color: white;
    font-weight: bolder;
    padding: 8px 12px;
    background-color: rgba(0, 0, 0, 0.4);
    max-width: 200px;
    width: 10rem;

    &:disabled {
      opacity: 0.4;
    }
  }
}

/* CUSTOMIZING RADIO BUTTONS */

.css-1rggdzb {
  background: #c4c4c4 !important;
  border-color: #c4c4c4 !important;
  color: #c4c4c4 !important;
  box-shadow: none !important;

  &[data-checked] {
    background: black !important;
    border-color: #c4c4c4 !important;
    color: black !important;
    box-shadow: none !important;
  }
}

/* MEDIA QUERIES */

@media screen and (max-width: 768px) {
  .form-container {
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    height: 70vh !important;
    width: 70%;
    text-align: center;
    padding: 2rem 3rem;
    margin-top: 2rem;
  }

  .radio-btn-container {
    display: flex;
    height: 2rem;
    justify-content: space-between !important;
    align-items: center;
  }
}

@media screen and (max-width: 325px) {
  .radio-btn-container {
    width: 90% !important;
  }
}

@media screen and (max-width: 320px) and (max-height: 480px) {
  .form-container {
    padding: 1rem 2rem !important;
  }
}
